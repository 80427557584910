import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpRequest
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { environment } from '@env/environment';
import { COOKIE_D_Id_PROP_NAME } from '@utils/storage.utils';
import { HttpStatusEnum } from '@models/http.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authService = inject(AuthService);
  const authToken = authService.getToken();
  const baseUrl = environment.apiUrl;
  const allowedRoutes = [`${baseUrl}/account/token`];
  const urlsToSkipToken = ['assets/i18n', 'assets/icons', 'google-analytics'];
  const exactUrlsToSkipToken = [
    `${baseUrl}/escort-profile/form-data`,
    `${baseUrl}/media/configs`,
    `${baseUrl}/account/form-data`,
    `${baseUrl}/commerce/options`,
    `${baseUrl}/promo-banner/public`
  ];
  const isAllowedReqUrl = !allowedRoutes.includes(req.url);
  const isSkipToken =
    urlsToSkipToken.some((url) => req.url.includes(url)) ||
    exactUrlsToSkipToken.some((url) => req.url === url);

  const deviceHash = authService.getDeviceHash();
  if (deviceHash) {
    req = req.clone({
      setHeaders: {
        [COOKIE_D_Id_PROP_NAME]: deviceHash
      }
    });
  }

  if (authToken && isAllowedReqUrl && !isSkipToken) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  return next(req).pipe(
    catchError((errResponse: HttpErrorResponse) => {
      if (
        [HttpStatusEnum.Unauthorized, HttpStatusEnum.Forbidden].includes(
          errResponse.status
        ) &&
        !allowedRoutes.includes(req.url)
      ) {
        authService.logout(true);
      }

      return throwError(() => errResponse);
    })
  );
}
